import user from './user.store.reducer'
import ui from './ui.slice'
import global_notification from './global.warning.store.reducer'
import global_process_bar from './global_process_bar.store.reducer'
import validate_code from 'entities/validateCode/store/validateCode.store.reducer'
import auth from 'store/auth.store.reducer'

const rootReducer = {
  user,
  global_process_bar,
  ui,
  global_notification,
  validate_code,
  auth,
}

export default rootReducer
