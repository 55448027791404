/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import ValidateCode from 'entities/validateCode'
import LoginPage from 'layout/login'
import RegisterPage from 'layout/register'
import LoginWithSocial from 'layout/component/login.sso'
import ForgotPassword from 'entities/forgotPassword'

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [
  {
    path: '/',
    main: LoginPage
  },
  {
    path: '/login',
    main: LoginPage
  },
  {
    path: '/login/facebook',
    main: LoginWithSocial
  },
  {
    path: '/login/google',
    main: LoginWithSocial
  },
  {
    path: '/register',
    main: RegisterPage
  },
  {
    path: '/validate-code',
    main: ValidateCode
  },
  {
    path: '/forgot-password',
    main: ForgotPassword
  },
  {
    path: '/forgot-password/:slug',
    main: ForgotPassword
  }
]
