//Library
import { useField, useForm } from '@shopify/react-form'
import React, { KeyboardEvent, Ref, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { AxiosError, AxiosResponse } from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Paper, Typography, keyframes, styled } from '@mui/material'

//Components
import TextField from 'components/TextField'
import _Helmet from 'components/helmet'

//Image
import  backgroundLogin  from 'entities/auth/media/images/backgroundLogin.svg';

//Store
import { useAppDispatch, useAppSelector } from 'config/store'
import { checkOTP, sendOTP } from './store/validateCode.store.reducer'

//Helpers
import helpers from 'helpers'

//Scss
import './media/validateCode.page.scss'
import __ from 'languages/index'
import {EnumTypeToast, useToast} from '../../hooks/useToast'
import {logout} from "../../store/user.store.reducer";
import getBackgroundLogin from 'entities/auth/media/images/backgroundLogin'

const ValidateCodePage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const channel_data = useAppSelector(state => state.user.channel_data)

  const inputRefs: RefObject<HTMLInputElement>[] = Array.from({ length: 6 }, () =>
  useRef<HTMLInputElement>(null)
  )

  const [validateCode, setValidateCode] = useState<string>('')
  const [numberOfValidations, setNumberOfValidations] = useState<number>(0)
  const [timeLeft, setTimeLeft] = useState<number>(120)
  const [isCounting, setIsCounting] = useState<boolean>(false)
  const [isLoadingValidated, setIsLoadingValidated] = useState<boolean>(false)
  const [isLoadingSendOTP, setIsLoadingSendOTP] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string>('')
  const toast = useToast();

  const validatedPhone = useMemo(()=> {
    return location.hash === '#phone'
  },[location])

  const { fields, dirty, submit } = useForm({
    fields: {
      form_input: useField<string>({
        value: '',
        validates: [
          inputVal => {
            if(inputVal && !helpers.isEmail(inputVal) && !validatedPhone){
              return 'Email sai định dạng'
            }
          }
        ]
      }),
    },
    async onSubmit(values) {
      try {
        setIsLoadingSendOTP(true)
        dispatch(sendOTP({user_input: values.form_input.trim()}))
          .unwrap()
          .then((res: AxiosResponse) => {
            setIsLoadingSendOTP(false)
            setSessionId(res?.data?.session_id)
            toast.show({
              content: `${__("validate_code_page_send_OTP_success")}`,
              type: EnumTypeToast.Success
            })
            setIsCounting(true)
          })
          .catch((error: AxiosError) => {
            console.log(`sendOTP_${error}`)
            setIsLoadingSendOTP(false)
            setSessionId('')
            switch (`${error}`) {
              case 'invalid_account':
                toast.show({
                  content: `${__("validate_code_page_phone_not_exist")}`,
                  type: EnumTypeToast.Error
                })
                break;
              case 'user_input_data_belong_to_other':
                toast.show({
                  content: `${__("notification_error_user_input_data_belong_to_other")}`,
                  type: EnumTypeToast.Error
                })
                break;
              case 'error_in_send_otp_service':
                toast.show({
                  content: `${__("validate_code_page_send_OTP_failed")}`,
                  type: EnumTypeToast.Error
                })
                break;
              default:
                toast.show({
                  content: `${__("validate_code_page_send_OTP_failed")}`,
                  type: EnumTypeToast.Error
                })
                break;
            }
          })

        return { status: 'success' }
      } catch (e: any) {
        console.error(`Submit error`, e)
        const message = e?.response?.data?.title ?? 'Undefined error. Try again!'
        const field = e?.response?.data?.errorKey ?? 'base'
        return { status: 'fail', errors: [{ field, message }] }
      }
    }
  })

  const handleChangeCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const regex = new RegExp(/\D/)
      if (regex.test(value)) return
      setValidateCode(validateCode => [validateCode, value].join(''))
    },
    [validateCode]
  )

  const handleInputChange = useCallback(
    (index: number, value: string) => {
      if (value && index < inputRefs.length - 1 && helpers.parseNumeric(value) >= 0) {
        inputRefs[index + 1]?.current?.focus()
      }
    },
    [inputRefs]
  )

  const handleKeyDown = useCallback(
    (index: number, event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        if (index > 0 && validateCode?.length < 6 && !validateCode.split('')[index + 1]) {
          inputRefs[index - 1]?.current?.focus()
          return  setValidateCode(validateCode =>
                    validateCode
                      .split('')
                      .filter((_, idx: number) => idx !== (index - 1))
                      .join('')
                  )
        }
        setValidateCode(validateCode =>
          validateCode
            .split('')
            .filter((_, idx: number) => idx !== index)
            .join('')
        )
      }
    },
    [inputRefs, validateCode]
  )

  const handleSubmitCode = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = {
      verified_code: validateCode,
      session_id: sessionId
    }
    setIsLoadingValidated(true)
    dispatch(checkOTP(formData))
      .unwrap()
      .then((res: AxiosResponse) => {
        setNumberOfValidations(0)
        setIsLoadingValidated(false)
        toast.show({
          content: `${__("validate_code_page_verify_success")}`,
          type: EnumTypeToast.Success
        })
        navigate('/', {replace: true})
      })
      .catch((error: AxiosError) => {
        console.log(`sendOTP_${error}`)
        setIsLoadingValidated(false)
        setNumberOfValidations(numberOfValidations => numberOfValidations + 1)
        toast.show({
          content: `${__("validate_code_page_confirm_failed")}`,
          type: EnumTypeToast.Error
        })
      })
  }, [validateCode, numberOfValidations,sessionId])

  const countTime = useMemo(() => {
    const minutes = Math.floor(timeLeft / 60)
    const seconds = timeLeft % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }, [timeLeft])

  useEffect(() => {
    let timer
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1)
      }, 1000)
    }
    if (timeLeft === 0) {
      setIsCounting(false)
      setTimeLeft(120)
      clearInterval(timer)
    }
    return () => {
      clearInterval(timer)
    }
  }, [isCounting, timeLeft])

  return (
    <>
      <_Helmet title={'validate_code_page_title'} />
      <ValidateCodePageContainer id="validateCodePage">
        <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.primary_color}))}')`,
          }}
        />

        <Box
          id={'background_svg_default'}
        />
        <Box className="wrapper">
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            maxWidth: theme => theme.spacing(76),
            height: 'auto'}}>
           <Box padding={theme => theme.spacing(3)}>
            <h2>{__("validate_code_page_title")}</h2>
            <Form onSubmit={submit} className="validateCodePage_phone_validate">
              <label className="fw-bold mb-1">{validatedPhone ? __("validate_code_telephone_number") : __('validate_code_page_email')}</label>
              <div className="validateCodePage_phone_validate_input">
                <TextField
                  {...fields.form_input}
                  autoFocus
                  onChange={(value: string) => {
                    validatedPhone ? fields.form_input?.onChange(value?.replace(/\D/, '')) : fields.form_input?.onChange(value)
                  }}
                  value={fields.form_input.value || ''}
                />
              </div>
              {!isCounting ? (
                <Button
                  type='submit'
                  sx={{textTransform: 'none'}}
                  disabled={!dirty || isLoadingSendOTP}
                  className="mt-3"
                >
                  {isLoadingSendOTP ? `${__("btn_processing")}` : `${__("validate_code_page_send_verification_code")}`}
                </Button>
              ) : (
                <Button sx={{textTransform: 'none'}} disabled={isCounting} className="mt-3">
                  {`${__("validate_code_page_resend")} ${countTime}`}
                </Button>
              )}
            </Form>
            <Form onSubmit={handleSubmitCode} className="validateCodePage_validate_number_code">
              <label className="fw-bold mb-1">{__("validate_code_page_verification_code")}</label>
              <div className="validateCodePage_validate_number_code_input">
                {inputRefs?.map((inputRef: Ref<HTMLInputElement>, index: number) => (
                  <Box component={Form.Control}
                    sx={{  
                      ':focus':{
                        boxShadow: theme=>`${theme.palette.primary.main} 0px 0px 6px 0.2rem !important`,
                        borderColor: theme=>`${theme.palette.primary.main} !important`
                      }
                    }}
                    key={`input_${index}`}
                    onChange={handleChangeCode}
                    value={validateCode.split('')[index] || ''}
                    placeholder="•"
                    maxLength={1}
                    className="text-center"
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                      e.target.select()
                    }}
                    ref={inputRef}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(index, e.target.value)
                    }
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
                  />
                ))}
              </div>
              <Button
                type='submit'
                sx={{textTransform: 'none'}}
                disabled={validateCode?.length < 6 || isLoadingValidated}
                className="mt-3"
              >
                {isLoadingValidated ? __('btn_processing') : __("validate_code_page_verify")}
              </Button>
              {numberOfValidations >= 3 && (
                <span
                  role="button"
                  onClick={() => navigate('/')}
                  className="d-flex text-decoration-underline text-primary justify-content-center mt-3"
                >
                  {__("validate_code_page_verify_later")}
                </span>
              )}
            </Form>
            <Box display={'flex'} className="mt-4">
              <span>{__("validate_code_page_confirm")}</span>
              <Button variant='text' sx={{p: theme=>theme.spacing(0,0.5)}} onClick={()=>dispatch(logout())} className=" ms-1 fw-bold text-decoration-none clickable">
                <Typography textTransform={'none'} color='primary.main'>
                  {__("validate_code_page_logout")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Paper>
        </Box>
      </ValidateCodePageContainer>
    </>
  )
}

export default ValidateCodePage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`;

const ValidateCodePageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  '& #background_svg': {
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    '@media(max-height: 648px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
}))
