//Library
import {useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {createPortal} from 'react-dom'
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material'

//Components
import ScrollToTop from 'components/ScrollToTop'
import EventTrigger from 'components/eventTrigger'
import Theme404 from './layout/404'
import AppToast from 'components/AppToast'

//Store
import {useAppDispatch, useAppSelector} from 'config/store'
import {separate_route as SeparateRoute} from './config/router-define'
import {clearError} from 'store/global.warning.store.reducer'

//Helper
import helpers from 'helpers'

//Css
// Styles must use direct files imports
import {getThemeByMode} from 'styles/themes'
import 'yet-another-react-lightbox/styles.css'
import {lightPalette} from './styles/themes/palettes/defaultPalete'
import {colorGenerator} from './styles/utils/colorPaletteGenerator'

const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '')

export default function App() {
  const channel_data = useAppSelector(state => state.user.channel_data)
  const errorMessage = useAppSelector(state => state.global_notification.errorMessage)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (channel_data?.country) {
      localStorage.setItem(
        'languageChannel',
        channel_data?.country?.toUpperCase() == 'VN' ? 'vi' : 'en'
      )
    }
  }, [channel_data?.country])

  useEffect(() => {
    const {hash} = window.location
    if (hash) {
      const hashURL = String(hash || ' ').replace('#', '')
      let _url = new URLSearchParams(hashURL)
      const user_referrer = _url.get('user_referrer') || ''
      if (user_referrer) {
        localStorage.setItem('user_referrer', user_referrer)
      }
    }
  }, [window.location])

  useEffect(() => {
    if (!errorMessage) return
    console.log('errorMessage', errorMessage)
    dispatch(clearError())
  }, [errorMessage])

  const chanelColor = helpers.validColor(channel_data?.primary_color)
    ? channel_data?.primary_color
    : '#DC3545'
  const paletteGenerated = {
    ...lightPalette,
    ...colorGenerator(chanelColor, 'primary')
  }

  const currentTheme = createTheme({
    ...getThemeByMode('light', {
      lightPalette: paletteGenerated
    })
  })

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline/>
      <BrowserRouter basename={process.env.PUBLIC_URL || baseHref}>
        <ScrollToTop/>
        <EventTrigger/>
        <>{createPortal(<AppToast/>, document.body)}</>
        <Routes>
          {SeparateRoute.map((Routex, index) => (
            <Route
              key={index}
              path={Routex.path}
              element={<Routex.main/>}
            />
          ))}

          <Route key={99999} path="*" element={<Theme404 includeHeader/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}
