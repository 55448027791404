import {useParams} from 'react-router-dom'

import Theme404 from '../../layout/404'
import ValidateCodePage from './validateCode.page'

export default function ValidateCode() {
  let useParam = {} as any
  useParam = useParams()
  let Param = useParam.slug || 'validate-code'
  let ActualPage: any

  switch (Param) {
    case 'validate-code':
      ActualPage = ValidateCodePage
      break

    default:
      ActualPage = Theme404
      break
  }
  return (
    <div>
      {<ActualPage/>}
    </div>
  )
}
