import __ from "languages/index";

var he = require('he');


export default class helper_parse {

  /**
   * Take only number in a string
   * @param {1} anything
   * @returns number
   * @deprecated - will remove in the future ...
   */
  parseInt(nnn: any, defaultx?: any) {
    var n = String(nnn || ' ').match(/\d+/gmi);
    if (!n)
      return (defaultx || 0);
    return parseInt(String(n));
  }


  /**
   *
   * @param nnn string with mix alphabet and numeric
   * @param defaultx
   * @returns Number only, but not bigint
   */
  parseNumeric(nnn: any, defaultx?: any) {
    var n = String(nnn || ' ')?.replace(/\D+/gmi, '');
    if (!n)
      return (defaultx || 0);
    return Number(String(n));
  }

  /**
   *
   * @param nnn string with mix alphabet and numeric
   * @param defaultx
   * @returns Number
   */

  validPhoneNumber(phoneNumber?: string) {
    if (!phoneNumber) return "";
    let phone = phoneNumber.split(" ")?.[1]
    if (!phone) return "";
    return phone.replace(/\D/g, '')
  }


  /**
   * Convert to float number
   * @param {*} n
   * @returns Float
   */
  parseFloat(n: any) {
    n = String(n || ' ');
    var f = parseFloat(n);
    if (Number.isNaN(f))
      return 0;
    return f;
  }

  /**
   * Take only A-Z a-z 0-9 only
   * @param {1} String
   * @returns Char only
   */
  parseChar(nnn: any) {
    var n = String(nnn || ' ')?.replace(/[^A-Za-z0-9]/g, '');
    if (!n)
      return '';
    return String(n).trim();
  }

  /**
   *
   * @param {1} Username
   * Chỉ chữ và dấu chấm ... không có gì khác ...
   */
  parseUsername(nnn: string | number) {
    var n = String(nnn || ' ')?.replace(/[^A-Za-z0-9.]/g, '');
    if (!n)
      return '';
    return String(n).trim();
  }

  /**
   * convert a string to Meta key, allow [^A-Za-z0-9-_]
   * @param {1} _string: string
   */
  parseMetaKey(_string: string | number) {
    var n = String(_string || ' ')?.replace(/[^A-Za-z0-9.\-_:]/gui, '');
    if (!n)
      return '';
    return String(n).trim();
  }

  /**
   * CLean string and return alphabet character only
   * @param {*} _string
   * @returns Alphabet only
   */
  parseAnphabet(_string: any) {
    var n = String(_string || ' ')?.replace(/[^\w!.?:]/g, '');
    if (!n)
      return '';
    return String(n).trim();
  }

  /**
   * Return URL if valid! Return empty string if not
   * @param {*} _url
   * @returns
   */
  parseURL(_url: any) {
    try {
      var n = String(_url || ' ')?.replace(/[^A-Za-z0-9.\_\-\/\#\&\!\;]/g, '-');
      if (!n)
        return '';
      return String(n)?.replace(/\-+/g, '-').trim();
    } catch (_) {
      return '';
    }
  }


  /**
   * return clean char without `"' or <>, for database
   * @param {*} string
   * @returns clean string
   */

  parseString(stringx: string) {
    var stringx = String(stringx || ' ')?.replace(/\'|\"|\`|\>|\</g, '');
    return String(stringx).trim();
  }

  /**
   * Parse string like html entities encode
   * @param {*} s
   * @returns
   */
  parseEntities(s: any) {
    s = String(s || ' ');
    return he.escape(s);
  }

  /**
   * Check and return valid JSON
   * @param {*} s
   * @returns Clean and valid JSON
   */
  parseJSON(s: any) {
    // preserve newlines, etc - use valid JSON
    s = String(s).trim();
    s = s?.replace(/\+/g, '');
    s = s?.replace(/\\n/g, "\\n")
      ?.replace(/\\'/g, "\\'")
      ?.replace(/\\"/g, '\\"')
      ?.replace(/\\&/g, "\\&")
      ?.replace(/\\r/g, "\\r")
      ?.replace(/\\t/g, "\\t")
      ?.replace(/\\b/g, "\\b")
      ?.replace(/\\f/g, "\\f");
    // remove non-printable and other non-valid JSON chars
    s = s?.replace(/[\u0000-\u0019]+/g, "");
    return (s);
  }


  /**
   * pase email list, eg: email, email, email ...
   * @param {*} emailList email, email
   * @returns array of valid email
   */
  parseEmailList(emailList: any) {
    function isEmail(email: any) {
      if (typeof email === 'undefined') {
        return false;
      }
      email = String(email || ' ').trim();

      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (filter.test(email)) {
        return true;
      }
      return false;
    };
    if (typeof emailList === 'undefined') return [];
    var s = String(emailList || ' ').split(',');
    return s.filter(function (email) {
      return isEmail(email) ? email : false;
    });
  }


  /**
   * Clean string for category name, tag name, and taxonomy name
   * DO NOT USE
   * @param {*} value
   * @returns clean string, without /\]]{}+_)(*&^%$#@)
   */
  parseUnchar(value: any) {
    if (typeof value !== "string") return "";
    var notAllowed = [";", "_", ">", "<", "'", "%", "$", "&", "/", "|", ":", "=", "*", "}", "{", "[", "]", "~", "!", "@", "#", "$", "^", "(", ")", "+"];
    for (var i = 0; i < value.length; i++) {
      if (notAllowed.indexOf(value.charAt(i)) > -1) {
        value = value?.replace(value.charAt(i), "");
      }
    }
    return String(value).trim();
  }

  checkValidatePassword(text: string) {
    const regex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/
    )
    return !regex.test(text)
  }

  numberWithCommas(x: any) {
    return x.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  secondsToHMS(seconds: number) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;

    let formattedHours = String(hours).padStart(2, '0');
    let formattedMinutes = String(minutes).padStart(2, '0');
    let formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  isEvenNumber(value: number): boolean {
    return value % 2 === 0;
  }

  truncatedFileName(fileName: string, maxLength: number = 25) {
    if (fileName.length <= 25) {
      return fileName
    } else {
      // Tính độ dài của phần tên file (không tính phần mở rộng)
      const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');

      // Cắt bớt tên file (không tính phần mở rộng) và thêm dấu chấm ba chấm
      const truncatedFileName = fileNameWithoutExtension.substring(0, maxLength) + '...';

      // Kết hợp tên file đã giới hạn độ dài với phần mở rộng
      return truncatedFileName + fileName.slice(fileNameWithoutExtension.length);
    }
  }

  convertToShortNumber(numberNeedConvert: number | string) {
    let numberValidate = Number(numberNeedConvert);
    if (Number.isNaN(numberNeedConvert)) return numberNeedConvert

    if (numberValidate >= 1e12) {
      return (numberValidate / 1e12).toFixed(2) + __("unit_trillion")?.toUpperCase();
    }

    if (numberValidate >= 1e9) {
      return (numberValidate / 1e9).toFixed(2) + __("unit_billion")?.toUpperCase();
    }

    if (numberValidate >= 1e6) {
      return (numberValidate / 1e6).toFixed(2) + __("unit_million")?.toUpperCase();
    }

    if (numberValidate >= 1e3) {
      return (numberValidate / 1e3).toFixed(2) + __("unit_thousand")?.toUpperCase();
    }

    return numberNeedConvert.toString();
  }
}
