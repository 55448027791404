import { createTheme } from '@mui/material'

const palette = createTheme().palette

export const colorGenerator = (color: string = '#fff', name: string) => {
  return {
    [name]: palette.augmentColor({
      color: {
        main: color
      },
      name
    })
  }
}
