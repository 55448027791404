import helpers from 'helpers/index';
import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';

type Type = 'text' | 'email' | 'number' | 'integer' | 'password' | 'search' | 'tel' | 'url' | 'date' | 'datetime-local' | 'month' | 'time' | 'week' | 'currency';

type TypedTextField = {
    className?: string,
    placeholder?: string;
    /** Initial value for the input */
    value?: string;
    /** Additional hint text to display */
    helpText?: React.ReactNode;
    /** Label for the input */
    label?: React.ReactNode;
    /** Visually hide the label */
    labelHidden?: boolean;
    /** Disable the input */
    disabled?: boolean;
    /** Disable editing of the input */
    readOnly?: boolean;
    /** Automatically focus the input */
    autoFocus?: boolean;
    /** Allow for multiple lines of input */
    multiline?: boolean | number;
    /** Error to display beneath the label */
    error?: Error | boolean | string;
    valid?: boolean;
    /** Determine type of input */
    type?: Type;
    /** Name of the input */
    name?: string;
    /** ID for the input */
    id?: string;
    /** Enable automatic completion by the browser. Set to "off" when you do not want the browser to fill in info */
    autoComplete?: string;
    /** Maximum character length for an input */
    maxLength?: number;
    /** Minimum character length for an input */
    minLength?: number;
    /** Indicate whether value should have spelling checked */
    spellCheck?: boolean;
    /** Visual required indicator, adds an asterisk to label */
    requiredIndicator?: boolean;
    /** Callback fired when value is changed */
    onChange?(value: string, id: string): void;
    /** Callback fired when input is focused */
    onFocus?: (event?: React.FocusEvent) => void;
    onBlur?: (event?: React.FocusEvent) => void;

    /** Migrate ... */
    runValidation?: (s: string) => void,
    setError?: (s: any) => void

}



/**
 * TextField, support validate and simple to use
 * @param props: @see TypedTextField
 * @returns 
 */
function TextField(props: TypedTextField) {
    let {
        id, 
        type,
        label,
        labelHidden,
        helpText,
        placeholder,
        disabled,
        readOnly,
        className,
        maxLength,
        minLength,
        name,
        value,
        onChange,
        onFocus,
        requiredIndicator,
        spellCheck,
        autoFocus,
        error,
        valid,
        autoComplete, 
        // migrate
        runValidation,
        setError,
        onBlur
    } = props;
    if ( typeof id === 'undefined' || !id ) id = helpers.slugify( Math.random() );
    if ( typeof type === 'undefined' || !type ) type = 'text';


    const onBlurCallback = (event: any) => {
        if ( typeof onBlur === 'function') {
            return onBlur.apply(this, event);
        }
        if ( typeof runValidation === 'function') {
            return runValidation.call(this, event.target.value);
        }
    }


    const onFocusCallback = (event: any) => {
        // onFocus
        if ( typeof onFocus === 'function') {
            return onFocus.apply(this, event);
        }
        if ( typeof setError === 'function') {
            return setError.call(this, '');
        }
    }
    
    return (
        <>
        { !labelHidden && label && (
            <Form.Label htmlFor={id}>{label} {requiredIndicator && ('*')}</Form.Label>
        ) }
        <Form.Control
            className={className ?? ''}
            type={type}
            id={id}
            placeholder={placeholder ?? ''}
            disabled={disabled ?? false}
            readOnly={readOnly ?? false}
            name={name ?? ''}
            maxLength={maxLength ?? 99999999999}
            minLength={minLength ?? 0}
            value={value ?? ''}
            spellCheck={spellCheck ?? false}
            onChange={(e) => { onChange ? onChange(e.target.value, e.target.id) : null } }
            aria-describedby={id + '_describe'}
            onFocus={onFocusCallback}
            onBlur={onBlurCallback}
            autoFocus={autoFocus}
            autoComplete={autoComplete ?? 'off'}
            isValid={valid}
            isInvalid={error ? true : false}
        />
        {
            error && (
                <Form.Text id={id + '_describe'} className='invalid-feedback' >
                    {error.toString()}
                </Form.Text>
            )
        }
        {
            helpText && (
                <Form.Text id={id + '_describe'} muted>
                    {helpText}
                </Form.Text>
            )
        }
      </>
    )
}

export default TextField;



/**
 * 
 * 


// Phần import

import TextField from "components/TextField";
import {
  lengthLessThan,
  lengthMoreThan,
  notEmpty,
  useField,
  useForm,
} from "@shopify/react-form";




# Phần khai báo 
  
const useFields = {
    title: useField<string>({
      value: 'defaultName',
      validates: [
        notEmpty('Trường này không được để trống.'),
        lengthLessThan(100, "Không được dài hơn 100 ký tự."),
        lengthMoreThan(2, "Tên phải dài hơn 2 ký tự.")
      ],
    })
  }


const {
  fields,
  submit,
  submitting,
  dirty,
  reset,
  submitErrors,
  makeClean,
} = useForm({
  fields: useFields,
  async onSubmit(values) {
    try {
        # dispatch ở đây, lấy value ra bằng cách values.tên trường
        alert(values.title);
      return { status: "success" };
    } catch (e: any) {
      console.error(`Submit error`, e);
      const message =
        e?.response?.data?.title ?? "Undefined error. Try again!";
      const field = e?.response?.data?.errorKey ?? "base";
      return { status: "fail", errors: [{ field, message }] };
    }
  },
});



# nơi gọi form ... được định nghĩa trên useFields

<TextField
label={"Tiêu đề"}
maxLength={100}
type="search"
requiredIndicator
{...fields.title}
/>



#trong trường hợp không chạy qua textField, thì làm như sau:

Lưu ý: value, onChange

<input 
    value={fields.title.value} 
    onChange={e => fields.title.onChange(e.target.value) } 
/>

<p className="error_display">{fields.title.error}</p>




* Lưu ý các constant: 
- dirty : người dùng chỉnh gì chưa, để cho phép lưu hoặc update ....
- submit: Function callback, dùng cho nút hoặc form.
- reset: Xóa trắng mọi form
- makeClean: xóa trắng ...
 */