import __ from "languages/index";
import {Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {notEmpty, useField, useForm} from "@shopify/react-form";
import "./media/css/index.scss";
import {useAppDispatch, useAppSelector} from "config/store";
import {updatePassword} from "entities/forgotPassword/forgot.password.store.reducer";
import helpers from "helpers";
import {AxiosError, AxiosResponse} from "axios";
import _Helmet from "components/helmet";
import backgroundLogin from 'entities/auth/media/images/backgroundLogin.svg';
import {Box, Button, IconButton, keyframes, Paper, styled, Typography} from "@mui/material";
import TextFieldMUI from 'components/TextFieldMUI'
import {ArrowLeftIcon, MobileIcon} from "icons";
import getBackgroundLogin from "entities/auth/media/images/backgroundLogin";

export interface TypeChangePassWold {
}

const ForgotPasswordPage: React.FC<TypeChangePassWold> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const channel_data = useAppSelector(state => state.user.channel_data)

  const checkMobile = helpers.isMobile();

  const useFields = {
    phoneOrEmail: useField<string>({
      value: '',
      validates: [
        notEmpty(`${__("forgot_password_email_or_phone_not_empty")}`),
        inputVal => {
          if (!helpers.isEmail(inputVal) && !helpers.isPhoneNumber(inputVal)) {
            return `${__("forgot_password_email_or_phone_error")}`
          }
        }
      ]
    }),

  }

  const {fields, submit, submitting, dirty, reset} = useForm({
    fields: useFields,
    async onSubmit(values) {
      await dispatch(updatePassword({
        user_input: values.phoneOrEmail
      }))
        .unwrap()
        .then((res: AxiosResponse) => {
          navigate('/forgot-password/verify' + window.location.search + window.location.hash, {
            state: {
              sessionId: res?.data?.session_id || '',
              user_input: res?.data?.user_input || ''
            }
          })
        })
        .catch((error: AxiosError) => {
          fields.phoneOrEmail.setError(__(error.message || 'system_meet_error'))
        })
      await helpers.sleep(1000)
      return {status: 'success'}
    }
  })


  const handleChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value} = e.target
      fields[name]?.onChange(value)
    },
    [fields]
  )

  return (
    <>
      <_Helmet title={'forgot_password_title'}/>
      <ForgotPasswordContainer id='forgotPasswordPage'>
      <Box
          id={'background_svg'}
          sx={{
            backgroundImage: `url('data:image/svg+xml,${encodeURIComponent(getBackgroundLogin({ primaryColor: channel_data?.primary_color }))}')`,
          }}
        />

        <Box
          id={'background_svg_default'}
        />

        <Box className="wrapper" id={'box_form'}>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              maxWidth: theme => theme.spacing(76),
              minWidth: checkMobile ? 'auto' : theme => theme.spacing(76),
              // height: checkMobile ? '100%' : 'auto',
              width: checkMobile ? '100%' : 'unset'
            }}>
            {
              checkMobile && (
                <IconButton sx={{mt: '24px', justifyContent: 'flex-start'}} onClick={() => navigate(-1)}>
                  <ArrowLeftIcon/>
                </IconButton>
              )
            }
            <Box
              display={'grid'}
              rowGap={theme => theme.spacing(3)}
              padding={theme => theme.spacing(3)}>
              <h1>{__("forgot_password_title")}</h1>

              {
                checkMobile && (
                  <Typography mt='24px' color='##626F82' fontSize='16px'>
                    {__("forgot_password_title_mobile")}
                  </Typography>
                )
              }
              <Form onSubmit={submit} id="forgotPassword" className="forgotPassword_content">
                <div className="forgotPassword_body">
                  <div className="forgotPassword_body_item">
                    <TextFieldMUI
                      type="text"
                      placeholder={__('login_user_name_label')}
                      autoFocus
                      name="phoneOrEmail"
                      value={fields.phoneOrEmail.value || ''}
                      onChange={fields.phoneOrEmail.onChange}
                      iconStart={<MobileIcon sx={{width: theme => theme.spacing(2.375)}}/>}
                    />

                    {fields.phoneOrEmail?.error && (
                      <span className="form-text text-danger">{fields.phoneOrEmail?.error}</span>
                    )}
                  </div>
                </div>
                <div className="forgotPassword_footer">

                  <Button onClick={submit} disabled={!dirty || submitting} type="submit"
                          sx={{p: theme => theme.spacing(2.1875, 2)}}>
                    <Typography textTransform={'none'}>
                      {submitting ? __('btn_processing') : __('forgot_password_btn')}
                    </Typography>
                  </Button>

                  {!checkMobile && (
                    <>
                      {/*<div className="helpText small text-muted mt-4">{___('In case you encounter difficulties retrieving your password, please contact the {support_center_link} for assistance in resetting your password.', {*/}
                      {/*  support_center_link: <Link to={'/about-me'}>{__('support_center')}</Link>*/}
                      {/*})}</div>*/}

                      <Link to={"/" + window.location.search + window.location.hash} className="forgotPassword_footer_comebackLogin">
                        {__("forgot_password_comeback_login")}
                      </Link>
                    </>
                  )}
                </div>
              </Form>
            </Box>
          </Paper>
        </Box>
      </ForgotPasswordContainer>
    </>
  )
}
export default ForgotPasswordPage

const animation = keyframes`
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.9;
  }
  20% {
    opacity: 0.8;
  }
  30% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.3;
  }
  80% {
    opacity: 0.2;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }

`;

const ForgotPasswordContainer = styled(Box)(({theme}) => ({
  height: '100vh',
  overflow: 'hidden',

  // backgroundImage: `url(${backgroundLogin})`,
  // backgroundAttachment: 'fixed',
  // backgroundPosition: 'top',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundColor: theme.palette.primary.main,
  '& #background_svg': {
    // height: '100%',

    // '& svg': {
    //   height: '100%',
    // }
    width: '100%',
    height: '100%',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  '& #background_svg_default': {
    backgroundImage: `url(${backgroundLogin})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    animation: `${animation} 3s forwards`,
    animationFillMode: 'forwards'
  },

  '& #box_form': {
    position: 'absolute',
    top: 0
  },
  '& .wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '@media(max-height: 468px)': {
      alignItems: 'flex-start',
      padding: theme.spacing(10, 0)
    }
  },
}))
