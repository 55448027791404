import { Components } from '@mui/material'
import { CurrentTheme } from 'styles/themes'

export const defaultInput: Components<CurrentTheme>['MuiTextField'] = {
  defaultProps: {
    variant: 'filled',
    fullWidth: true,
    InputProps: {
      disableUnderline: true,
      size: 'small'
    }
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.background.default,
      pointerEvents: 'auto',
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.spacing(1.5)
      },
      '& .MuiInputBase-input': {
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.palette.background.default,
        caretColor: theme.palette.primary.main
      },

      '&:hover': {
        backgroundColor: theme.palette.background.secondary,
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.background.secondary
        }
      },
      '& .MuiInputBase-root.Mui-focused': {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.disabled,
        '& .MuiInputBase-input': {
          backgroundColor: theme.palette.background.secondary || theme.palette.grey[300]
        },
        '& .MuiInputAdornment-filled': {
          color: 'inherit'
        }
      }
    })
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        '&:hover': {
          backgroundColor: theme.palette.background.secondary,
          '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.secondary
          },
          '& .MuiInputBase-input': {
            backgroundColor: theme.palette.background.secondary
          }
        }
      })
    }
  ]
}
