import { PaletteOptions } from '@mui/material'

const white = '#fff'

export const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#DC3545',
    light: '#E35D6A',
    dark: '#a51c30',
    background: '#FDEFF1',
    backgroundHover: '#F8D7DA',
    contrastText: white
  },

  error: {
    main: '#FF2528',
    light: '#f55954',
    dark: '#d70103'
  },
  warning: {
    main: '#faad14',
    light: '#ffc53d',
    dark: '#e18700'
  },
  info: {
    main: '#1677ff',
    light: '#4096ff',
    dark: '#0958d9'
  },
  success: {
    main: '#52c41a',
    light: '#73d13d',
    dark: '#389e0d'
  },
  divider: '#E0E2E6',
  background: {
    default: '#F3F4F5',
    secondary: '#E7E9EC',
    paper: white
  },
  text: {
    primary: '#0E1426',
    secondary: '#626F82',
    disabled: '#C0C5CD'
    // hint: '#B5B5C3'
  }
}
